<template>
  <div class="user-document-wrapper">
    مدارک به درستی بارگذاری نشده
  </div>
</template>

<script>
export default {
  name: "UserDocument"
};
</script>

<style scoped>
.user-document-wrapper {
  width: 170px;
  padding: 6px 12px;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #dc0000;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
</style>
