<template>
  <div id="btn-edit" @click.prevent="goEditProfile">
    <img src="../../assets/images/fa-regular_edit.svg" alt="" />
    <div class="text">ویرایش</div>
  </div>
</template>
<script>
export default {
  name: "BtnEdit",
  props: ["link"],
  data() {
    return {};
  },
  methods: {
    goEditProfile() {
      this.$router.push("/account-authentication");
    }
  }
};
</script>
<style scoped>
#btn-edit {
  min-width: 120px;
  max-width: 120px;
  min-height: 30px;
  max-height: 30px;
  background: #1f3c88;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.text {
  color: #fff;
  margin-right: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
</style>
