<template>
  <div id="dashboard">
    <loading :can-cancel="true"></loading>
    <PanelHeader title="داشبورد" />
    <div class="dashboard-container">
      <InfromationAlert class="alert" />
      <section class="information">
        <div class="profile-information">
          <ProfileInformation
            :first_name="user.first_name"
            :last_name="user.last_name"
            :mobile="user.mobile"
            :national_code="user.national_code"
            :email="user.email"
            :city="user.city"
            :province="user.province"
          />
        </div>
        <div class="wallet-information">
          <WalletInformation :balance="data.balance" />
        </div>
      </section>
      <section class="transaction">
        <div class="transaction-chart">
          <TransactionStatusCard
            :max_daily_transaction="transaction.max_daily_transaction"
            :sum_today_transaction="transaction.sum_today_transaction"
            :amount_allowed_today="transaction.amount_allowed_today"
          />
        </div>
        <div class="sell-and-buy-currency">
          <SellAndBuyCurrency :cryptos="cryptos" />
        </div>
      </section>
      <section class="order-table">
        <SortTable :orders="orders" :max-height="true" />
      </section>
      <section class="table">
        <CurrencyTable :items="cryptos ? cryptos : []" :headers="tableHeader" />
      </section>
    </div>
  </div>
</template>
<script>
import PanelHeader from "../components/PanelHeader";
import InfromationAlert from "../components/InformationAlert";
import ProfileInformation from "../components/ProfileInformation";
import WalletInformation from "../components/WalletInformation";
import TransactionStatusCard from "../components/Cards/TransactionStatusCard";
import SortTable from "@/components/Table/SortTable";
import SellAndBuyCurrency from "@/components/Cards/SellAndBuyCurrency";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CurrencyTable from "@/components/Table/CurrencyTable";

import axios from "axios";

export default {
  components: {
    SellAndBuyCurrency,
    PanelHeader,
    InfromationAlert,
    ProfileInformation,
    WalletInformation,
    TransactionStatusCard,
    SortTable,
    Loading,
    CurrencyTable
  },
  data() {
    return {
      showDepositModal: false,
      showWithdrawModal: false,
      user: [],
      data: [],
      orders: [],
      cryptos: null,
      transaction: {
        max_daily_transaction: 4000000,
        sum_today_transaction: 3000000,
        amount_allowed_today: 1000000
      },
      tableHeader: [
        {
          id: 1,
          title: "ارز"
        },
        {
          id: 2,
          title: "نام ارز"
        },
        {
          id: 3,
          title: "قیمت خرید از ما"
        },
        {
          id: 4,
          title: "قیمت فروش به ما"
        },
        {
          id: 5,
          title: "موجودی"
        }
      ]
    };
  },
  async mounted() {
    await this.getDashboardInformation();
  },
  methods: {
    async getDashboardInformation() {
      let loading = this.$loading.show();
      try {
        let response = await axios("/panel/dashboard");
        this.user = response.data.user;
        this.data = response.data;
        this.orders = response.data.orders;
        this.cryptos = response.data.crypto;
      } catch (error) {
        console.log(error);
      }
      loading.hide();
    }
  }
};
</script>
<style scoped>
#dashboard {
  /*width: calc(100% - 250px);*/
  /*margin-right: auto;*/
  width: 100%;
}

.dashboard-container {
  /*padding: 0 40px;*/
  padding: 0 10px;
  margin-bottom: 20px;
}

.alert {
  margin-top: 20px;
}

.information {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}

.profile-information {
  width: 100%;
  margin-bottom: 20px;
}

.wallet-information {
  width: 100%;
}

.transaction {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}

.order-table {
  margin-top: 20px;
}

.table {
  margin-top: 20px;
}

.panel-footer {
  margin-top: 10px;
}

.sell-and-buy-currency {
  width: 100%;
  margin-top: 20px;
}

.transaction-chart {
  width: 100%;
  /*margin-bottom: 20px;*/
}

/*@media (max-width: 1200px) {*/
/*  #dashboard {*/
/*    width: 100%;*/
/*  }*/

/*  .dashboard-container {*/
/*    padding: 0 20px;*/
/*  }*/

/*  .profile-information {*/
/*    width: 63%;*/
/*  }*/

/*  .transaction-chart {*/
/*    width: 32%;*/
/*  }*/

/*  .wallet-information {*/
/*    width: 35%;*/
/*  }*/
/*}*/

/*@media (max-width: 860px) {*/
/*  .profile-information {*/
/*    width: 100%;*/
/*  }*/
/*}*/

/*@media (max-width: 960px) {*/
/*  .dashboard-container {*/
/*    padding: 0 20px;*/
/*  }*/

/*  .profile-information {*/
/*    width: 100%;*/
/*    margin-bottom: 20px;*/
/*  }*/

/*  .wallet-information {*/
/*    width: 100%;*/
/*  }*/
/*}*/

@media (min-width: 992px) {
  #dashboard {
    width: calc(100% - 250px);
    margin-right: auto;
  }

  .sell-and-buy-currency {
    width: 53%;
    margin-top: unset;
  }

  .transaction-chart {
    width: 45%;
    /*margin-bottom: 20px;*/
  }

  .profile-information {
    width: 60%;
    /*margin-bottom: 20px;*/
  }

  .wallet-information {
    width: 38%;
  }

  .transaction {
    margin-top: unset;
  }
}

@media (min-width: 1200px) {
  #dashboard {
    width: calc(100% - 250px);
    margin-right: auto;
    /*width: 100%;*/
  }

  .dashboard-container {
    padding: 0 40px;
    /*padding: 0 10px;*/
  }
}
</style>
