<template>
  <div id="transaction-status-card">
    <div class="header">وضعیت سقف تراکنش امروز</div>
    <div class="max-daily-transaction">
      سقف تراکنش روزانه:
      <span>{{ max_daily_transaction.toLocaleString("fa-IR") }}</span>
      تومان
    </div>
    <div class="sum-today-transaction">
      جمع خرید های امروز:
      <span>{{ sum_today_transaction.toLocaleString("fa-IR") }}</span>
      تومان
    </div>
    <div class="amount-allowed-today">
      میزان مجاز باقیمانده:
      <span>{{ amount_allowed_today.toLocaleString("fa-IR") }}</span>
      تومان
    </div>
    <div class="chart">
      <div>
        <apexchart
          width="310"
          type="pie"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TransactonStatusCard",
  props: {
    max_daily_transaction: {
      type: Number
    },
    sum_today_transaction: {
      type: Number
    },
    amount_allowed_today: {
      type: Number
    }
  },
  data() {
    return {
      series: [22, 44],
      chartOptions: {
        labels: ["میزان خرید امروز", "میزان باقی مانده"],
        chart: {
          width: 150
        },
        colors: ["#5C6699", "#070D59"],
        dataLabels: {
          enabled: false
        },
        legend: {
          position: "left"
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      }
    };
  }
};
</script>
<style scoped>
#transaction-status-card {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 15px 20px;
  /*min-height: 325px;*/
  min-height: 400px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  position: relative;
}
.header {
  font-style: normal;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 14px;
}
.max-daily-transaction {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #121212;
  margin-bottom: 10px;
}
.sum-today-transaction {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #121212;
  margin-bottom: 10px;
}
.sum-allowed-today {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #121212;
  margin-bottom: 10px;
}
.amount-allowed-today {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #121212;
}
.chart {
  margin-top: 60px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

/deep/.apexcharts-legend-text {
  font-family: "Vazir" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-right: 5px;
  color: #121212;
}
/deep/.apexcharts-legend-series {
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  #transaction-status-card {
    min-height: 326px;
    height: 326px;
  }
}
</style>
