<template>
  <div
    class="drop-down-input"
    :class="[error ? 'error' : '']"
    v-click-outside="onClickOutside"
  >
    <div class="drop-down" @click.prevent="showDropDown = !showDropDown">
      <img
        src="../../assets/images/input-dropdown-icon.svg"
        alt=""
        class="drop-down-icon"
      />
      <div class="title">
        {{ selected ? selected.symbol : items[0] ? items[0].symbol : null }}
      </div>
      <img
        :src="selected ? selected.image : items[0] ? items[0].image : null"
        alt=""
        class="title-image"
      />
    </div>
    <!--    <div class="input"></div>-->
    <label>
      <input type="text" v-model="value" @input="validateNumber($event)" />
    </label>
    <div class="drop-down-container" v-show="showDropDown">
      <div
        class="item"
        v-for="item in items"
        :key="item.id"
        @click="handleSelectedItem(item)"
      >
        <img class="item-image" :src="item.image" alt="" />
        <div class="item-title">{{ item.english_name }}</div>
      </div>
    </div>
    <div class="error-massage" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["error"],
  name: "DropDownInput",
  data() {
    return {
      showDropDown: false,
      items: {},
      value: null,
      selected: null,
      regex: "^0$|^[1-9]\\d*$|^\\.\\d+$|^0\\.\\d*$|^[1-9]\\d*\\.\\d*$",
      // regex: "^[0-9]*(\\.{1})?[0-9]*$",
      validated: false
      // regex: '[^\\d]+',
    };
  },
  mounted() {
    this.getAllCrypto();
  },
  computed: {
    errorMassage() {
      if (this.error) {
        return this.error;
      }
      if (!this.validated) {
        return "";
      }
      if (!this.value) {
        return "این فیلد الزامی است. لطفا آن را پر کنید";
      }

      return "";
    }
  },
  methods: {
    async getAllCrypto() {
      try {
        let response = await axios("/crypto");
        this.items = response.data;
        this.$emit("selected", this.items[0]);
      } catch (error) {
        console.log(error);
      }
    },
    onClickOutside() {
      this.showDropDown = false;
    },
    handleSelectedItem(item) {
      this.selected = item;
      this.showDropDown = false;
      this.$emit("selected", item);
    },
    validateNumber(e) {
      let value = e.target.value,
        data = e.data,
        reg = /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;
      if (reg.test(data)) {
        console.log("reg");
        if (reg !== null) {
          if (value === null) value = "";
          if (!reg.test(value)) value.slice(0, -1);
          if (value === "") value = null;
        }
      } else value = value.replace(data, "");

      this.value = value;
      this.$emit("update:value", value);
    }
  }
};
</script>

<style scoped>
.drop-down-input {
  width: 427px;
  height: 40px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.drop-down {
  width: 34%;
  height: 40px;
  background: rgba(107, 136, 209, 0.3);
  padding-right: 10px;
  padding-left: 22px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.input {
}

.title-image {
  width: 30px;
  height: 30px;
}

.drop-down-container {
  width: 140px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(-1%, 102%);
  background: white;
  z-index: 1;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 2px;
  cursor: pointer;
}

.item:hover {
  background-color: rgba(31, 60, 136, 0.1);
}

.item-image {
  width: 25px;
  height: 25px;
}

.drop-down-input {
  position: relative;
}

input {
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  border: none;
  padding: 6px 5px;
  width: 66%;
  /* line-height: 10px; */
  font-size: 16px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.error {
  border: 1px solid #ff6b6b;
}

.error-massage {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

@media (max-width: 440px) {
  .drop-down {
    width: 35%;
    gap: 5px;
  }

  .drop-down-icon {
    width: 15px;
  }

  .title-image {
    width: 12px;
  }

  .title {
    font-size: 14px;
  }

  .drop-down-container {
    width: 99%;
  }
}
</style>
