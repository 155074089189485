<template>
  <div class="drop-down-input">
    <div class="drop-down">
      <img
        src="../../assets/images/input-dropdown-icon.svg"
        alt=""
        class="drop-down-icon"
      />
      <div class="title">تومان</div>
      <img src="../../assets/images/iran.svg" alt="" class="title-image" />
    </div>
    <label>
      <input
        type="text"
        disabled
        :value="value"
        style="background-color: #ffffff; color: rgba(0,0,0, 0.5);"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: "DropDownInput",
  props: ["value"],
  data() {
    return {
      showDropDown: false
    };
  }
};
</script>

<style scoped>
.drop-down-input {
  width: 427px;
  height: 40px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.drop-down {
  width: 143px;
  height: 40px;
  background: rgba(107, 136, 209, 0.3);
  padding-right: 10px;
  padding-left: 22px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  opacity: 0.4;
}

.input {
}

.title-image {
  width: 30px;
  height: 30px;
}

.drop-down-container {
  width: 140px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(-1%, 102%);
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 2px;
}

.item-image {
  width: 25px;
  height: 25px;
}

.drop-down-input {
  position: relative;
}

input {
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  border: none;
  padding: 7px 5px;
  width: 66%;
  /* line-height: 10px; */
  font-size: 16px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media (max-width: 440px) {
  .drop-down {
    width: 35%;
    gap: 5px;
  }

  .drop-down-icon {
    width: 15px;
  }

  .title-image {
    width: 12px;
  }

  .title {
    font-size: 14px;
  }

  .drop-down-container {
    width: 99%;
  }
}
</style>
