<template>
  <div id="alert-container" v-if="show">
    <div class="content">
      برای "فروش ارزهای دیجیتال مانند وبمانی و پرفکت مانی" نیاز به ارسال مدارک و
      احراز هویت نیست<br />
      رای "خرید از ایکسچنج از" و "فروش رمزارزها به ایکسچنج از" نیاز به احراز
      هویت دارید.
      <span class="text-color"> برای احراز هویت اینجا کلیک کنید.</span>
    </div>
    <div class="close" @click="close">
      <img src="../assets/images/jam_close.svg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "InformationAlert",
  data() {
    return {
      show: true
    };
  },
  methods: {
    close() {
      this.show = false;
    }
  }
};
</script>
<style scoped>
#alert-container {
  width: 100%;
  min-height: 70px;
  max-height: auto;
  border-radius: 10px;
  background: rgba(107, 136, 209, 0.1);
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.content {
  font-size: 14px;
}
.text-color {
  cursor: pointer;
  color: red;
}
.close {
  cursor: pointer;
}
.close:hover img {
  opacity: 0.8;
}
@media (min-width: 768px) {
  .content {
    font-size: 16px;
  }
}
</style>
