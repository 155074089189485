<template>
  <div id="profile-information">
    <div class="profile-image">
      <img src="../assets/images/profile-information-icon.svg" alt="" />
    </div>
    <div class="information">
      <div class="header">
        <div class="fullname">
          {{ first_name ? first_name + " " + last_name : "----  ----" }}
        </div>
        <div class="verification">
          <div class="verification__image">
            <img
              @mouseover="bankCardHover"
              src="../assets/images/card.svg"
              alt=""
            />
            <CardBankTooltip v-if="bankCard" class="tooltip" />
          </div>
          <div class="verification__image">
            <img
              @mouseover="userDocumentHover"
              src="../assets/images/la_id-card-solid.svg"
              alt=""
            />
            <UserDocument v-if="userDocument" class="tooltip" />
          </div>
          <div class="verification__image">
            <img src="../assets/images/phone-green.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="first-row">
        <div class="mobile">شماره تلفن: {{ mobile || "----" }}</div>
        <div class="national-code">کدملی : {{ national_code || "----" }}</div>
        <div class="email">آدرس ایمیل: {{ email || "----" }}</div>
      </div>
      <div class="secend-row">
        <div class="province">استان: {{ province || "----" }}</div>
        <div class="city">شهرستان: {{ city || "----" }}</div>
      </div>
    </div>
    <BtnEdit class="btn" />
  </div>
</template>
<script>
import BtnEdit from "../components/Button/BtnEdit";
import CardBankTooltip from "@/components/Tooltip/CardBankTooltip";
import UserDocument from "@/components/Tooltip/UserDocument";

export default {
  name: "ProfileInformation",
  props: [
    "first_name",
    "last_name",
    "mobile",
    "national_code",
    "email",
    "province",
    "city"
  ],
  components: {
    UserDocument,
    CardBankTooltip,
    BtnEdit
  },
  data() {
    return {
      bankCard: false,
      userDocument: false
    };
  },
  methods: {
    goEditAccount() {
      // console.log("click");
      return this.$router.push("/account-authentication");
    },
    bankCardHover() {
      this.bankCard = true;
      if (this.userDocument === true) {
        this.userDocument = false;
      }
      setTimeout(() => {
        this.bankCard = false;
      }, 2000);
    },
    userDocumentHover() {
      if (this.bankCard === true) {
        this.bankCard = false;
      }
      this.userDocument = true;
      setTimeout(() => {
        this.userDocument = false;
      }, 2000);
    }
  }
};
</script>
<style scoped>
#profile-information {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  position: relative;
}

.information {
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0;
  font-weight: 500;
  font-size: 16px;
}

.verification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 35%;
}

.first-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0;
  font-size: 14px;
}

.secend-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
  font-size: 14px;
}

.verification__image {
  position: relative;
}

.tooltip {
  position: absolute;
  right: 0;
  transform: translateX(40%);
}

.fullname {
  font-family: "Vazir Medium FD";
}
/* #profile-information {
  min-height: 170px;
  max-width: 710px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  position: relative;
} */
/* .profile-image {
  width: 100px;
  height: 100px;
} */
/* .verification img {
  width: 25px;
  height: 25px;
} */
/* .verification {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
/* .information {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-right: 30px;
} */
/* .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
} */
/* .fullname {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
} */
/* .first-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  font-size: 14px;
} */
/* .secend-row {
  width: 100%;
  display: flex;
  margin-top: 13px;
  font-size: 14px;
} */
/* .city {
  margin-right: 135px;
} */
/* .btn {
  position: absolute;
  bottom: 20px;
  left: 20px;
} */

@media (min-width: 320px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px 0;
  }

  .verification {
    width: 30%;
  }

  .first-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px 0;
    font-size: 14px;
    justify-content: space-between;
  }

  .secend-row {
    display: flex;
    flex-direction: revert;
    align-items: center;
    margin: 5px 0;
    justify-content: space-between;
    font-size: 14px;
  }
}

@media (min-width: 576px) {
  #profile-information {
    /*width: 63%;*/
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0 20px;
  }

  .profile-image {
    width: 100px;
    height: 100px;
    margin: 15px 20px 55px 30px;
  }

  .btn {
    position: absolute;
    bottom: 20px;
    left: 25px;
  }

  .secend-row {
    width: 50%;
    display: flex;
    flex-direction: row;
  }

  .information {
    height: 100%;
  }

  .verification {
    width: 20%;
  }

  .first-row {
    margin: 20px 0 10px 0;
  }
}

@media (min-width: 768px) {
  #profile-information {
    flex-wrap: initial;
  }

  .first-row {
    margin: 20px 0 10px 0;
  }

  .information {
    height: 100%;
  }

  s .verification {
    width: 30%;
  }
}

@media (min-width: 960px) {
  .verification {
    width: 23%;
  }
}
</style>
