<template>
  <div class="sell-buy-currency">
    <loading :can-cancel="true"></loading>
    <div class="header" :class="[left ? 'left' : '']">
      <div class="sell-currency-section" @click="toggleRightSide">
        فروش ارز
      </div>
      <div class="buy-currency-section" @click="toggleLeftSide">
        خرید ارز
      </div>
    </div>
    <div v-if="!left" class="content">
      <div class="preview">
        <div class="preview-header">
          فروش {{ selected ? selected.symbol : "" }} به ویستا
        </div>
        <img
          class="preview-image"
          :src="selected ? selected.image : ''"
          alt=""
        />
        <div class="preview-price-header">
          قیمت خرید
        </div>
        <div class="price">
          <span>{{
            selected
              ? parseInt(selected.sale_price_from_us).toLocaleString()
              : ""
          }}</span>
          تومان
        </div>
      </div>
      <div class="input-group">
        <DropDownInput
          class="first-input"
          @selected="selectedSaleHandler"
          :value.sync="currencySaleAmount"
          :error="error"
        />
        <DisableTommanInput
          class="last-input"
          :value="convertSaleAmount ? convertSaleAmount.toLocaleString() : 0"
        />
        <SellButton :on-click="sellCurrencyHandler" />
      </div>
    </div>
    <div v-if="left" class="content">
      <div class="preview">
        <div class="preview-header">
          خرید {{ selected ? selected.symbol : "" }} از ویستا
        </div>
        <img
          class="preview-image"
          :src="selected ? selected.image : ''"
          alt=""
        />
        <div class="preview-price-header">
          قیمت فروش
        </div>
        <div class="price">
          <span>{{
            selected
              ? parseInt(selected.purchase_price_from_us).toLocaleString()
              : ""
          }}</span>
          تومان
        </div>
      </div>
      <div class="input-group">
        <DisableTommanInput
          class="first-input"
          :value="
            convertPurchaseAmount ? convertPurchaseAmount.toLocaleString() : 0
          "
        />
        <DropDownInput
          class="last-input"
          @selected="selectedPurchaseHandler"
          :value.sync="currencyPurchaseAmount"
          :error="error"
          :cryptos="items"
        />
        <BuyButton :onClick="buyCurrencyHandler" />
      </div>
    </div>
  </div>
</template>

<script>
import DropDownInput from "@/components/input/DropDownInput";
import DisableTommanInput from "@/components/input/DisableTommanInput";
import SellButton from "@/components/Button/SellButton";
import BuyButton from "@/components/Button/BuyButton";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  name: "SellAndBuyCurrency",
  props: ["cryptos"],
  components: {
    BuyButton,
    DropDownInput,
    DisableTommanInput,
    SellButton,
    Loading
  },
  data() {
    return {
      left: false,
      selected: null,
      currencyPrice: null,
      items: [],
      error: null,

      currencyPurchaseAmount: null,
      convertPurchaseAmount: null,

      currencySaleAmount: null,
      convertSaleAmount: null
    };
  },
  // mounted() {
  //   console.log(this.items);
  // },
  watch: {
    currencyPurchaseAmount(newValue) {
      let amount = newValue * this.selected.purchase_price_from_us;
      this.convertPurchaseAmount = Math.floor(amount);
    },
    currencySaleAmount(newValue) {
      let amount = newValue * this.selected.sale_price_from_us;
      this.convertSaleAmount = Math.floor(amount);
    },
    cryptos(newValue) {
      this.items = newValue;
    }
  },
  methods: {
    toggleRightSide() {
      this.left = false;
    },
    toggleLeftSide() {
      this.left = true;
    },
    selectedSaleHandler(item) {
      this.selected = item;
      let amount = this.currencySaleAmount * item.sale_price_from_us;
      this.convertSaleAmount = Math.floor(amount);
    },
    selectedPurchaseHandler(item) {
      this.selected = item;
      let amount =
        this.currencyPurchaseAmount * this.selected.purchase_price_from_us;
      this.convertPurchaseAmount = Math.floor(amount);
    },
    async getCurrencyPrice() {
      let loading = this.$loading.show();
      try {
        let response = await axios("/currency");
        this.currencyPrice = response.data.price;
      } catch (error) {
        console.log(error);
      }
      loading.hide();
    },
    sellCurrencyHandler() {
      this.error = null;
      if (!this.currencySaleAmount) {
        this.error = "لطفا تعداد را وارد کنید";
        return false;
      }
      this.$router.push(
        `/digital-currency-equations?section=sell&coin=${this.selected.id}&sell_amount=${this.currencySaleAmount}`
      );
    },
    buyCurrencyHandler() {
      this.error = null;
      if (!this.currencyPurchaseAmount) {
        this.error = "لطفا تعداد را وارد کنید";
        return false;
      }
      this.$router.push(
        `/digital-currency-equations?section=buy&coin=${this.selected.id}&buy_amount=${this.currencyPurchaseAmount}`
      );
    }
  }
};
</script>

<style scoped>
.sell-buy-currency {
  min-width: 100%;
  min-height: 325px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 10px 20px;
}

.header {
  display: flex;
  position: relative;
  padding-bottom: 10px;
}

.header::before {
  transition: right 0.2s linear, background-color 0.2s linear;
  content: "";
  display: block;
  height: 3px;
  width: 50%;
  /*background-color: rgba(7, 13, 89, 0.2);*/
  position: absolute;
  bottom: 0;
  right: 0;
  /*border-bottom: 5px solid #1F3C88;*/
  border-radius: 5px;
  background-color: #dc0000;
}

.header::before .left {
  content: "";
  display: block;
  height: 3px;
  width: 50%;
  background: rgba(7, 13, 89, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  background: #1f3c88;
}

.header.left::before {
  right: 50%;
  background-color: #039000;
}

.header::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: rgba(7, 13, 89, 0.2);
  position: absolute;
  bottom: 1px;
}

.sell-currency-section {
  flex: 1;
  text-align: center;
  cursor: pointer;
  font-family: "Vazir Medium FD";
}

.buy-currency-section {
  flex: 1;
  text-align: center;
  cursor: pointer;
  font-family: "Vazir Medium FD";
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.preview-header {
  margin-bottom: 10px;
}

.preview-image {
  margin-bottom: 25px;
  width: 100px;
  height: 100px;
}

.price {
  color: #070d59;
  font-weight: 500;
  font-size: 20px;
  font-family: "Vazir Medium FD";
}

.price span {
  font-family: "Vazir Medium FD";
  font-size: 20px;
}

.content {
  display: flex;
  margin-top: 30px;
}

.input-group {
  width: 100%;
}

.first-input {
  margin-bottom: 30px;
  width: 100%;
}

.last-input {
  margin-bottom: 45px;
  width: 100%;
}

@media (max-width: 100px) {
  .sell-buy-currency {
    min-width: 100%;
  }
}

@media (max-width: 768px) {
  .sell-buy-currency {
    min-width: 100%;
  }

  .content {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .preview {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
