<template>
  <div id="wallet-information">
    <DepositModal v-if="showDepositModal" @close="showDepositModal = false" />
    <WithdrawModal
      v-if="showWithdrawModal"
      @close="showWithdrawModal = false"
    />
    <div class="header">
      <img src="../assets/images/wallet.svg" alt="" />
      <div class="title">موجودی کیف پول</div>
    </div>
    <div class="inventory">
      <span>{{ balance ? balance.toLocaleString() : "0" }}</span> تومان
    </div>
    <div class="btn-container">
      <BtnDeposit @clicked="handleShowDepositeModal" class="btn-deposit" />
      <BtnWithdraw @clicked="handleShowWithdrawModal" class="btn-withdraw" />
    </div>
  </div>
</template>
<script>
import BtnDeposit from "../components/Button/BtnDeposit";
import BtnWithdraw from "../components/Button/BtnWithdraw";
import DepositModal from "@/components/Modals/DepositModal";
import WithdrawModal from "@/components/Modals/WithdrawModal";

export default {
  name: "WalletInformation",
  props: ["balance"],
  components: {
    WithdrawModal,
    DepositModal,
    BtnDeposit,
    BtnWithdraw
  },
  data() {
    return {
      showDepositModal: false,
      showWithdrawModal: false
    };
  },
  methods: {
    handleShowDepositeModal() {
      this.showDepositModal = true;
    },
    handleShowWithdrawModal() {
      this.showWithdrawModal = true;
    }
  }
};
</script>
<style scoped>
#wallet-information {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 100%;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

#wallet-information div {
  flex: 1 1 auto;
}

img {
  width: 20px;
}

.header {
  margin-top: 18px;
  display: flex;
  align-items: center;
}

.title {
  margin-right: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.inventory {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #070d59;
}

.image {
  width: 20px;
  height: 20px;
}

.btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.btn-deposit {
  /*margin-left: 5px;*/
}

.btn-withdraw {
  /*margin-right: 5px;*/
}

@media (min-width: 568px) {
  .title {
    font-size: 16px;
  }

  .inventory {
    font-size: 25px;
  }

  img {
    width: 40px;
    height: 40px;
  }

  .btn-deposit {
    /*margin-left: 10px;*/
  }

  .btn-withdraw {
    /*margin-right: 10px;*/
  }
}
</style>
